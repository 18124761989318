<template>
	<!-- 添加——新增主sku 弹窗 -->
	<el-dialog :title='td("新增主SKU")' :visible="addskuVisible" width="80%" @close="onClose()"
		:close-on-click-modal="false" append-to-body>
		<!-- 基础信息 -->
		<h4>{{td('基本信息')}}</h4>
		<el-form ref="forms" :model="addform" :rules="rules" label-position="top" label-width="110px">
			<el-row>
				<el-col :span="8">
					<el-form-item :label='td("中文名称")' prop="motherName">
						<el-input v-model="addform.motherName" :placeholder='td("请输入中文名")' style="width: 96%">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("英文名称")'>
						<el-input v-model="addform.motherEnname" :placeholder='td("请输入英文名称")' style="width: 96%">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("本地名称")'>
						<el-input v-model="addform.localMotherName" :placeholder='td("请输入本地名称")' style="width: 96%">
						</el-input>
					</el-form-item>
				</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item :label='td("店铺")'>
							<el-select v-model="addform.shopId" :placeholder='td("请选择店铺")' style="width: 96%;">
								<el-option v-for="(item, index) in warhoureleList" :key="index" :label="item.name"
									:value="item.shopId" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item :label='td("主SKU图片")'>
							<ImageUpload v-model="addform.imageUrl" :pictureType="pictureType" />
						</el-form-item>
					</el-col>
					<!-- <el-col :span="12">
          <el-form-item label="品类">
            <Treeselect v-model="addform.skuType" :options="treeform" placeholder="选择所属品类" style="width: 60%" />
          </el-form-item>
        </el-col> -->
					<el-col :span="8">
						<el-form-item :label='td("备注信息")'>
							<el-input v-model="addform.remark" type="textarea" :show-word-limit='true' maxlength="200"
								:rows="8" :placeholder='td("请输入内容")' style="width: 96%; height: 50%">
							</el-input>
						</el-form-item>
					</el-col>
			</el-row>

			<el-divider></el-divider>

			<!-- 辅助信息 -->
			<h4>{{ td('辅助信息') }}</h4>
			<el-row>
				<el-col :span="6">
					<el-form-item :label='td("长")'>
						<el-input v-model="addform.goodsLength" style="width: 96%"
							onkeyup="this.value=this.value.replace(/[^\d.]/g,'')">
							<template slot="append">cm</template>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label='td("宽")'>
						<el-input v-model="addform.Wide" style="width: 96%"
							onkeyup="this.value=this.value.replace(/[^\d.]/g,'')">
							<template slot="append">cm</template>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label='td("高")'>
						<el-input v-model="addform.goodsHeight" style="width: 96%"
							onkeyup="this.value=this.value.replace(/[^\d.]/g,'')">
							<template slot="append">cm</template>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item :label='td("重量")'>
						<el-input v-model="addform.goodsWidth" style="width: 96%"
							onkeyup="this.value=this.value.replace(/[^\d.]/g,'')">
							<template slot="append">kg</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-divider></el-divider>
			<!-- 创建子SKU -->
			<div class="information">
				<h4>{{td('创建子SKU')}}</h4>
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAddWmsInOrderDetail"
					style=" margin-right: 10px; ">{{td('新增')}}</el-button>
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="hhandleDeleteErpSkuSon">{{td('删除')}}
				</el-button>

				<el-table :data="addform.erpSkuSonList" :row-class-name="rowWmsInOrderDetailIndex"
					@selection-change="handleWmsInOrderDetailSelectionChange" ref="wmsInOrderDetail" class="ys">
					<el-table-column type="selection" width="50" align="center" />
					<el-table-column :label="td('序号')" align="center" prop="index" width="115" />

					<el-table-column :label="td('中文名')" prop="goodsName">
						<template slot-scope="scope">
							<el-input v-model="scope.row.goodsName" :placeholder="td('请输入子商品名')" />
						</template>
					</el-table-column>
					<el-table-column :label="td('英文名')" prop="sonEnname">
						<template slot-scope="scope">
							<el-input v-model="scope.row.sonEnname" :placeholder="td('请输入子英文名')" />
						</template>
					</el-table-column>
					<el-table-column :label="td('本地名')" prop="localSonName">
						<template slot-scope="scope">
							<el-input v-model="scope.row.localSonName" :placeholder="td('请输入子本地名')" />
						</template>
					</el-table-column>

					<el-table-column :label="td('Seller SKU')" prop="sellerSku">
						<template slot-scope="scope">
							<el-input v-model="scope.row.sellerSku" :placeholder="td('请输入Seller SKU')" />
						</template>
					</el-table-column>

					<el-table-column :label="td('规格型号')" prop="model">
						<template slot-scope="scope">
							<el-input v-model="scope.row.model" :placeholder="td('请输入规格型号')" />
						</template>
					</el-table-column>
					<el-table-column :label="td('商品图片')">
						<template slot-scope="scope">
							<ImageUpload v-model="scope.row.goodsImg" :pictureType="pictureSKUType" />
						</template>
					</el-table-column>
					<el-table-column :label="td('商品成本价')">
						<template slot-scope="scope">
							<el-form-item class="goodsPrice" :prop="'erpSkuSonList.'+ scope.$index +'.goodsPrice'"
								:rules="rules.goodsPrice" style="margin-top:20px">
								<el-input v-model="scope.row.goodsPrice" :placeholder="td('请输入商品成本价')" />
							</el-form-item>
						</template>
					</el-table-column>
				</el-table>

			</div>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="onClose" style="margin-right: 10px;">{{td('取消')}}</el-button>
			<el-button type="primary" @click="onSave" style="margin-right: 10px;">{{td('保存')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		addErpmother,
		treeErpmother
	} from "@/api/goods/Erpmother";
	import ImageUpload from "@/utils/ImageUpload/index.vue";
	import Cookies from "js-cookie";
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";

	export default {
		name: "add-sku",
		props: ["addskuVisible", "warhoureleList", "treeform"],
		components: {
			ImageUpload,
			Treeselect
		},
		data() {
			//验证数字的规则
			var checkEmail = (rule, value, cb) => {
				//验证数字的正则表达式
				const regEmail = /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/
				if (regEmail.test(value)) {
					//合法邮箱
					return cb();
				}
				cb(new Error(this.td("请输入数字(最多保留两位小数)")));
			};
			return {
				// 表单参数
				form: {},
				checkedErpSkuSon: [],
				// 子sku
				wmsInOrderDetailList: [],
				// 存放照片
				fileList: [],
				addform: {
					localMotherName: null,
					motherEnname: null,
					motherName: null,
					motherSku: null,
					depotId: null,
					imageUrl: null,
					remark: null,
					goodsLength: null,
					Wide: null,
					goodsWidth: null,
					goodsHeight: null,
					warehouse: null,
					skuType: null,
					username: null,
					source: 10991,
					erpSkuSonList: [],
				},
				Treeform: {},
				formdata: {},
				pictureType: "picture-card",
				pictureSKUType: "picture",
			};
		},
		// created() {
		//   this.onTree();
		// },
		computed: {
			rules() {
				return {
					motherName: [{
						required: true,
						message: this.td("中文名称不能为空"),
						trigger: "blur"
					}],
					goodsPrice: [{
						required: true,
						message: this.td("数量不能为空"),
						trigger: "blur"
					}, {
						validator: this.checkEmail,
						trigger: "blur"
					}],
				}
			}
		},
		methods: {
			onClose() {
				this.addform = {};
				this.addform.erpSkuSonList = []
				this.$emit("update:addskuVisible", false);
				this.addform.source = 10991
				this.$refs.forms.clearValidate()
			},
			onSave() {
				this.addform.username = Cookies.get("username");
				this.$refs.forms.validate(valid => {
					if (valid) {
						addErpmother(this.addform).then(res => {
							if (res.code == 200) {
								this.$message.success(this.td("添加成功"));
								this.$bus.$emit("refresh")
							}
							this.$emit("updateList")
							this.$emit("update:addskuVisible", false);
							this.addform = {};
							this.addform.erpSkuSonList = []
							this.addform.source = 10991
						})
					}
				})
			},
			chanSelect(data) {
				//选择仓库
				this.addform.depotId = this.warhoureleList[data].id;
			},
			// onTree() {
			//   treeErpmother().then( res => {
			//     this.Treeform = res.data;
			//   });
			// },

			handleAddWmsInOrderDetail() {
				let obj = {};
				obj.goodsWidth = this.addform.Wide
				obj.goodsHeight = this.addform.goodsHeight
				obj.goodsLength = this.addform.goodsLength
				obj.goodsWeight = this.addform.goodsWidth
				obj.goodsPrice = ""
				obj.goodsName = ""
				obj.goodsImg = ""
				obj.remark = ""
				obj.stockNum = ""
				obj.wholeOut = ""
				obj.auditOut = ""
				obj.depotId = ""
				obj.userId = ""
				obj.sellerSku = ""
				obj.depotSku = ""
				obj.model = ""
				obj.skuStatus = ""
				obj.wmsStatus = ""
				obj.sonEnname = ""
				obj.localSonName = ""
				this.addform.erpSkuSonList.push(obj)
			},
			/** 入库明细序号 */
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1;
			},
			handleWmsInOrderDetailSelectionChange(selection) {
				this.checkedErpSkuSon = selection.map(item => item.index);
			},
			/** 入库明细删除按钮操作 */
			hhandleDeleteErpSkuSon() {
				if (this.checkedErpSkuSon.length == 0) {
					this.$message.warning(this.td("请先选择要删除的子SKU数据"));
				} else {
					const erpSkuSonList = this.addform.erpSkuSonList;
					const checkedErpSkuSon = this.checkedErpSkuSon;
					this.addform.erpSkuSonList = erpSkuSonList.filter(function(item) {
						return checkedErpSkuSon.indexOf(item.index) == -1;
					});
				}
			},
		},
	};
</script>

<style scoped>
	/* 弹窗 */
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 0 20px 10px 20px;
		height: 70vh;
		overflow: auto;

	}

	.goodsPrice ::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	.ys ::v-deep .el-table_2_column_17,
	.el-table__cell {
		padding-top: 33px;
	}
</style>
