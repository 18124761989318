<template>
	<!-- 添加——新增主sku 弹窗 -->
	<el-dialog :title='td("修改SPU")' :visible="addskuVisible" width="1000px" @close="onClose()">
		<!-- 基础信息 -->
		<h4>{{td('基本信息')}}</h4>
		<el-form ref="form" :model="form" label-position="top" label-width="90px">
			<el-row>
				<el-col :span="8">
					<el-form-item :label='td("商品名称")'>
						<el-input v-model="tabList.motherName" :placeholder='td("请输入商品名称")' style="width: 310px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("英文名称")'>
						<el-input v-model="tabList.motherEnname" :placeholder='td("请输入英文名称")' style="width: 310px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("本地名称")'>
						<el-input v-model="tabList.localMotherName" :placeholder='td("请输入本地名称")' style="width: 310px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("主SKU")'>
						<el-input v-model="tabList.itemSku" disabled style="width: 310px"></el-input>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item :label='td("店铺")'>
						<el-select v-model="tabList.shopId" :placeholder='td("请选择店铺")' style="width: 310px;">
							<el-option v-for="(item, index) in warhoureleList" :key="index" :label="item.name"
								:value="item.shopId" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="8">
					<el-form-item :label='td("主SKU图片")'>
						<ImageUpload v-model="tabList.imageUrl" :pictureType='pictureType' style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("备注信息")'>
						<el-input v-model="tabList.remark" type="textarea" :show-word-limit='true' maxlength="200"
							:rows="10" :placeholder='td("请输入内容")' style="width: 100%;height: 100%;">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button style="margin-right: 10px;" @click="onClose()">{{td("取消")}}</el-button>
			<el-button type="primary" style="margin-right: 10px;" @click="onSave(tabList)">{{td("确定")}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		updateErpmother
	} from '@/api/goods/Erpmother'
	import ImageUpload from '@/utils/ImageUpload/index.vue'
	import Cookies from "js-cookie"

	export default {
		name: "add-sku",
		props: ['addskuVisible', 'tabList', 'warhoureleList'],
		components: {
			ImageUpload
		},
		data() {
			return {
				// 下拉框测试
				options: [],
				value: "",
				// 表单参数
				form: {},
				checkedErpSkuSon: [],
				// 子sku
				wmsInOrderDetailList: [],
				// 存放照片
				fileList: [],
				addform: {
					localMotherName: null,
					motherEnname: null,
					motherName: null,
					motherSku: null,
					imageUrl: null,
					remark: null,
					goodsLength: null,
					Wide: null,
					goodsWidth: null,
					goodsHeight: null,
					warehouse: null,
					depotId: null,
					username: null,
					erpSkuSonList: []
				},
				adrules: {
					goodsPrice: [{
						required: true,
						message: (this.td("母商品名不能为空")),
						trigger: "blur"
					}],
				},
				formdata: {},
				pictureType: "picture-card",
				pictureSKUType: "picture",
			}
		},
		methods: {
			getTableList(value) {
				this.ErpSkuList = value;
			},
			onClose() {
				this.$emit('updateList')
				this.$emit('update:addskuVisible', false);
			},
			onSave(data) {
				data.username = Cookies.get("username");
				updateErpmother(data).then(res => {
					this.$emit('updateList')
					this.$emit('update:addskuVisible', false)
					this.$message.success(this.td("修改成功"));
				})
			},
			handleAddWmsInOrderDetail() {
				let obj = {};
				obj.goodsWidth = this.addform.Wide;
				obj.goodsHeight = this.addform.goodsHeight;
				obj.goodsLength = this.addform.goodsLength;
				obj.goodsWeight = this.addform.goodsWidth;
				obj.goodsPrice = "";
				obj.goodsName = "";
				obj.goodsImg = "";
				obj.remark = "";
				obj.stockNum = "";
				obj.wholeOut = "";
				obj.auditOut = "";
				obj.depotId = "";
				obj.userId = "";
				obj.shopSku = "";
				obj.depotSku = "";
				obj.model = "";
				obj.skuStatus = "";
				obj.wmsStatus = "";
				obj.sonEnname = "";
				obj.localSonName = "";
				this.tabList.erpSkuSonList.push(obj)
			},
			/** 入库明细序号 */
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1
			},
			handleWmsInOrderDetailSelectionChange(selection) {
				this.checkedErpSkuSon = selection.map(item => item.index);
			},
			/** 入库明细删除按钮操作 */
			hhandleDeleteErpSkuSon() {
				if (this.checkedErpSkuSon.length == 0) {
					this.msgError(this.td("请先选择要删除的子SKU数据"));
				} else {
					const erpSkuSonList = this.tabList.erpSkuSonList;
					const checkedErpSkuSon = this.checkedErpSkuSon;
					this.tabList.erpSkuSonList = erpSkuSonList.filter(function(item) {
						return checkedErpSkuSon.indexOf(item.index) == -1
					});
				}
			},

		}
	}
</script>

<style scoped>
	/* 弹窗 */
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 0 20px 10px 20px;
		height: 70vh;
		overflow: auto;
	}

	.add-sku .el-radio {
		margin-right: 15px;
	}
</style>
