<template>
	<div>
<el-table ref="Partialtable" :data="tableList" tooltip-effect="dark" row-key="motherSku" :row-style="{height: '80px'}" @selection-change="handleSelectionChange"
			  :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }" >
			  <el-table-column type="selection" align="center"  />
			  <el-table-column prop="motherName" :label='td("商品名")'  :show-overflow-tooltip="true" width="200">  <!-- :render-header="renderHeader" -->
			    <template slot-scope="scope">
			      <el-link type="primary" :underline="false"  @click="toogleExpand(scope.row)" >
			        {{ scope.row.motherName }}
			      </el-link>
			    </template>
			  </el-table-column>
			  <el-table-column prop="imageUrl" :label='td("图片")'>
			    <template slot-scope="scope">
			      <img :src="scope.row.imageUrl" style="width: 50px; height: 50px" />
			    </template>
			  </el-table-column>
			  <el-table-column prop="wmsStatus" :label='td("状态")'  width="150" >
			    <template slot-scope="scope">
			      <div style="color: rgb(255 0 5);">{{ tfp('WMS_STATUS',scope.row.wmsStatus)}}</div>
			    </template>
			  </el-table-column>
			  <el-table-column prop="shopId" :label='td("店铺")'  width="150" >
			    <template slot-scope="scope">
			      {{ showShopType(scope.row.shopId)}}
			    </template>
			  </el-table-column>
			  <el-table-column  :label='td("来源")' width="150"  >
			    <template slot-scope="scope">
			      {{tfp('ERP_ORDER_SOURCE',scope.row.source)}}
			    </template>
			  </el-table-column>
			  <el-table-column prop="updateTime" :label='td("操作时间")'  />
			  <el-table-column prop="createTime" :label='td("创建时间")'  />
			  <el-table-column prop="remark" :label='td("描述")'  :show-overflow-tooltip="true" />

			  <!-- 子列表 -->
			  <el-table-column type="expand" width="1">
			    <template slot-scope="scope">
			      <el-table :data="allotDetailMap.get(scope.row.motherSku)" :key="detailTableKey" row-key="sonSku" class="table-in-table"  style="width: 100%; "  border >
			        <el-table-column :label='td("中文名")' prop="goodsName" />
			        <el-table-column :label='td("英文名")'  prop="sonEnname" />
			        <el-table-column :label='td("本地名")'  prop="localSonName" />
			        <el-table-column :label='td("商品图片")' prop="goodsImg" >
			          <template slot-scope="scope">
			            <img :src="scope.row.goodsImg" style="width: 50px; height: 50px" />
			          </template>
			        </el-table-column>
					<el-table-column :label='td("状态")'  prop="wmsStatus" >
						<template slot-scope="scope">
						  <div :style="{'color': scope.row.wmsStatus == 10825 ? 'rgb(34 255 4)' : 'rgb(255 0 5)'}">{{ tfp('WMS_STATUS',scope.row.wmsStatus)}}</div>
						</template>
					</el-table-column>
			        <el-table-column :label='td("Seller SKU")'  prop="sellerSku" />
					<el-table-column :label='td("Shop SKU")'  prop="shopSku" />
			        <el-table-column :label='td("仓库SKU")'  prop="depotSku" />
			        <el-table-column :label='td("规格型号")'  prop="model" />
			        <el-table-column :label='td("商品成本价")' prop="goodsPrice"/>
			        <el-table-column :label='td("宽度")'  prop="goodsWidth"/>
			        <el-table-column :label='td("高度")'  prop="goodsHeight"/>
			        <el-table-column :label='td("长度")'  prop="goodsLength"/>
			        <el-table-column :label='td("重量")'  prop="goodsWeight"/>
				    <el-table-column :label='td("SKU类型")'  prop="skuType"/>
				    <el-table-column :label='td("组合SKU")'  prop="relateId"/>
			        <el-table-column :label='td("操作")' width="150" align="center" fixed="right" class-name="small-padding fixed-width">
			          <template slot-scope="scope">
						<el-button size="mini" type="text" icon="el-icon-edit" @click="checkrelationWms(scope.row)" >{{td('关联wms')}}</el-button><br>
			            <el-button size="mini" type="text" icon="el-icon-edit" @click="updateSon(scope.row)" >{{td('修改')}}</el-button>
<!--			            <el-button size="mini" type="text" icon="el-icon-delete" @click="delSon(scope.row.sonSku)" >{{td('删除')}}</el-button>-->
			          </template>
			        </el-table-column>
			      </el-table>
			    </template>
			  </el-table-column>
			  <el-table-column :label='td("操作")' fixed="right" width="150">
			    <template slot-scope="scope">
				  <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="AddsSKU(scope.row)" >{{td('创建子SKU')}}</el-button><br>
			      <el-button size="mini" type="text" icon="el-icon-edit" @click.stop="xiugai(scope.row)" >{{td('修改')}}</el-button>
			      <!-- <el-button size="mini" type="text" icon="el-icon-refresh" @click="handleTbu(scope.row.motherSku)" :disabled="scope.row.wmsStatus == '10825'? true:false ">{{td('导入到云仓')}}</el-button> -->
<!--			      <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row.motherSku)" >{{td('删除')}}</el-button >-->
			    </template>
			  </el-table-column>
			</el-table>
		<!-- 表格 -->

		<!-- 分页 -->
		<pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getTableList" />

		<!--添加主SKU按钮----弹窗组件 -->
		<addSku :addskuVisible.sync="addskuDig" @updateList="updateList" :warhoureleList="ShopList" :treeform="treeform" />
		<!--添加子SKU按钮----弹窗组件 -->
		<addSonsku :SonskuVisible.sync="addSonDig" :tabList="addSonlList" @updateList="updateList"/>
		<!--修改主SKU按钮----弹窗组件 -->
		<updateSku :addskuVisible.sync="updateskuDig" :tabList="updateModelList" :warhoureleList="ShopList" @updateList="updateList" ref="update" />
		<!--修改子SKU按钮----弹窗组件 -->
		<updateSonsku :SonskuVisible.sync="updateSonDig" :tabList="updateSonlList"/>
		<!--关联wms按钮----弹窗组件 -->
		<RelationWms :relationVisible.sync="relationDig" :tabList="relationList" :RelationList="checkrelationList" @updateList="updateList" />
	</div>
</template>

<script>

import addSku from "../dialog/addsku";
import addSonsku from "../dialog/addSonsku";
import updateSku from "../dialog/updatesku";
import updateSonsku from "../dialog/updateSonsku";
import RelationWms from "../dialog/RelationWms";
import axios from 'axios'

import { getListtt,getson,getshop,getrelation } from "@/api/warehouse/"
import {TreeList,delErpmother,Synchronization,delsonSKU,treeErpmother} from "@/api/goods/Erpmother.js";
import { synchronizationSku,synchronizationSkuShopee,synchronizationPlTbu } from "@/api/order/orderList.js"
import { getToken } from "@/utils/auth";
import {  Debounce,Throttle } from "@/utils/bd.js"

export default {
  name: "fristSKU",
  components: { addSku, addSonsku, updateSku, updateSonsku, RelationWms},
  data() {
    return {
      val: "",
	  activeName: "first",
      total: 0,
      fullscreenLoading: false,
      form:{},
      // 页面父数据
      tableList: [],
      // 页面子数据
      sonTableList:[],
      //子表数据
      allotDetailMap: new Map(),
      // 级联选择器
      treeform: [],
      //内嵌 table key
      detailTableKey: false,
      // 控制弹窗的开关
      // catalogueDig: false,
      editskuDig: false,
      deleteskuDig: false,
      addskuDig: false,
      updateskuDig: false,
      updateSonDig: false,
      addSonDig: false,
      intoskuDig: false,
      outskuDig: false,
	  relationDig: false,
	  relationList: [],
	  checkrelationList: [],

      warhoureleList: [],
      addSonlList: [],

      treeKey: null,
      updateModelList: {},
      updateSonlList: {},

      queryParams: {
        keywords: null,
        skuType: null,
        source: null,
        wmsStatus: 10952,
        pageNum: 1,
        pageSize: 10,
      },

      upload: {
        // 是否显示弹出层
        open: false,
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: this.baseURL + "/erp/Erpmother/importErpSku/",
      },
      //批量同步到云仓
      single: true,
      multiple: true,
      selectData:[],
      handplTbuData:[],
	  ShopList: []
    }
  },
  created() {
 //    'this.getTableList()
    // this.getShop()
 //    this.addwareList()
	// this.getrelationList();'
  },
  computed:{
  	showShopType: function() {
  	  return function(number) {
  	    for (let i = 0; i < this.ShopList.length; i++) {
  	      if (number == this.ShopList[i].shopId) {
  	        return this.ShopList[i].name
  	      }
  	    }
  	  }
  	},
  },
  methods: {
	  /** 加载数据 */
	  loadData(){
	  	this.getTableList()
	  	this.getShop()
	  	this.addwareList()
	  	this.getrelationList();
	  },
    // 获取页面数据
    getTableList() {
      TreeList(this.queryParams).then( res => {
        this.tableList = res.rows
        this.total = res.total
      })
    },
	receiveList(val){
		this.queryParams = val;
		this.getTableList();
	},
    addwareList() {
      getListtt().then((res) => {
        this.warhoureleList = res.data;
      })
    },
	getShop() {
	  getshop().then((res) => {
	    this.ShopList = res.rows;
	  })
	},
	getrelationList(){
		getrelation().then((res) => {
		  this.relationList = res.rows;
		})
	},
    xiugai(data) {
      this.updateModelList = data;
      this.updateskuDig = true;
	  this.$refs.update.getTableList(this.updateModelList);
    },
    updateSon(data) {
      this.updateSonlList = data;
      this.updateSonDig = true;
    },
	checkrelationWms(data){
	  this.checkrelationList = data;
	  this.relationDig = true;
	},
    AddsSKU(data) {
      this.addSonlList = data;
      this.addSonDig = true;
    },
    //搜索选择品类
    changeValue(data) {
      // console.log(data);
      this.queryParams.skuType = data
    },
    reset() {
      this.form = {
        keywords: null,
        skuType: null,
        source: null,
        wmsStatus: null,
      }
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getTableList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.resetForm("queryForm");
      this.handleQuery();
    },
    //同步SKU
    handlSynchronization:
      Throttle( function() {
        this.fullscreenLoading = true,
        axios.all([ synchronizationSku(),synchronizationSkuShopee() ]).then(
          axios.spread( () => {
            this.$message.success(this.td("同步成功"))
            this.fullscreenLoading = false;
            this.getTableList()
          })
        ).catch( () => {
            this.$message.success(this.td("同步成功"))
            this.fullscreenLoading = false;
            this.getTableList()
          })
      },3000),
    //同步到云仓
    handleTbu(val1) {
      // Synchronization(val1).then( res => {
      //   this.$message.success(this.td("同步成功"))
      //   this.getTableList();
      //   this.depotId = null
      // });
      let datas = [val1]
      synchronizationPlTbu(datas).then( res =>{
        if(res.code !== 200) {
          return this.$message.error( this.td(res.msg))
        }
        this.$message.success(this.td(res.msg))
        this.getTableList();
      })
    },
    //多选
    handleSelectionChange(selection) {
      this.single = selection.length !== 1
      this.multiple = !selection.length
      this.selectData = selection
    },
    //批量同步到云仓
    handplTbu() {
      if(this.selectData.length == 0 ) {
        return this.$message.warning( this.td('请选择批量数据'))
      }

      for (let index = 0; index < this.selectData.length; index++) {
        // if(this.selectData[index].wmsStatus = "10825") {
        //   return this.$message.warning(this.td('选中的数据中，部分已同步，请勿重复操作！'))
        // }
        this.handplTbuData.push(this.selectData[index].motherSku)
      }

      synchronizationPlTbu(this.handplTbuData).then( res =>{
        if(res.code !== 200) {
          return this.$message.error( this.td(res.msg))
        }
        this.$message.success(this.td(res.msg))
        this.getTableList();
      })
      this.handplTbuData =[]

    },
    //根据(母SKU)删除SKU
    async handleDelete(id) {
      const confirmResult = await this.$confirm(
        this.td("此操作将删除(主键母SKU)，是否继续?"),
        this.td("提示"),
        {
          confirmButtonText: this.td("确定") ,
          cancelButtonText: this.td("取消"),
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") return this.$message.info( this.td("已取消删除"));
      delErpmother(id).then((res) => {
        if (res.code !== 200) {
          return this.$message.error( this.td("删除“主键母SKU”失败") );
        }
        this.$message.success( this.td("删除“主键母SKU”成功"));
        this.getTableList()
        this.addwareList()
      });
    },
    //根据(子SKU)删除SKU
    async delSon(id) {
      const confirmResult = await this.$confirm(
        this.td("此操作将删除(子SKU)，是否继续?"),
        this.td("提示"),
        {
          confirmButtonText: this.td("确定"),
          cancelButtonText: this.td("取消"),
          type: "warning",
        }
      ).catch((err) => err)
      if (confirmResult !== "confirm") return this.$message.info( this.td("已取消删除"));
      delsonSKU(id).then((res) => {
        if (res.code !== 200) {
          return this.$message.error( this.td("删除“子SKU”失败"));
        }
        this.$message.success( this.td("删除“子SKU”成功"));
        this.getTableList();
      })
    },

    getAllotDetail(row) {
      //根据 motherSku 获取子表
      getson(row).then( res => {
        // 使用 map 结构的方式保存子表
        this.allotDetailMap.set(row,res.rows);
        this.detailTableKey = !this.detailTableKey;
        this.reset();
      })
    },
    //点击母商品名，显示子SKU
    toogleExpand(val) {
      this.getAllotDetail(val.motherSku);
	  let $table = this.$refs.Partialtable;
	  $table.toggleRowExpansion(val);
    },
    updateList() {
      this.getTableList();
    },
    //选择仓库的值
    chanSelect(val) {
      this.depotId = val
    },


    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.msg, this.td("导入结果"), { dangerouslyUseHTMLString: true });
      this.getOrder()
    },
    handleImport() {
      this.upload.open = true;
    },
    /** 下载模板操作 */
    importTemplate() {
      // this.download(
      //   "https://interface.6pl.com.cn/erp/Erpmother/importTemplate",
      //   {
      //     ...this.queryParams,
      //   },
      //   `sku_${new Date().getTime()}.xlsx`
      // );
	  window.open('/excel_model/ERP_SKU.xlsx',"_blank");
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.msg, this.td("导入结果"), { dangerouslyUseHTMLString: true });
      this.getTableList()
	  this.upload.shopId = null;
    },
    // 提交上传文件
    submitFileForm(upload) {
	  if(this.upload.shopId == null){
		this.$message.info( this.td("请选择店铺"))
	  }else {
		// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/Erpmother/importData?shopId="+this.upload.shopId,
		// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/Erpmother/importData/"+this.upload.shopId,
		console.log(this.upload);
		this.$refs.upload.submit();
	  }
    },
    // renderHeader(h, {column}) {
    //   let header = column.label.split(' ');
    //   return [h('p', [
    //     h('p', {}, header[0]),
    //     h('span', {}, header[1])
    //   ])];
    // },
  },
}
</script>

<style scoped>
.search {
  position: relative;
  background-color: #fff;
  height: 80px;
  margin-bottom: 16px;
}
.box {
  position: absolute;
  top: 20px;

}
.search .el-form {
  margin: 0 16px;
  color: #333333;
  margin-right: 0;
}

  .search .el-form-item {
   margin-bottom: 0;
 }
  .search ::v-deep .el-form-item__label {
    margin-right: 24px;
    padding: 0;
  }

  .search ::v-deep .el-input__inner{
    width: 220px;
    height: 32px;
  }
  .search ::v-deep .el-form--inline .el-form-item {
   margin-right: 0;

 }
 .el-form-item:not(:first-child) {
   margin-left: 32px;
 }
 .el-form-item:last-child {
    margin-left: 16px;
 }

 .btns {
   position: absolute;
   top: 24px;
   right: 16px;
 }
 .search .el-button{
  width: 74px;
  height: 32px;
 }

.search .item1 ::v-deep .el-input__suffix {
  line-height: 50px;
}
.search .item3 ::v-deep .el-input__icon {
  line-height: 40px;
}
.search .item2 ::v-deep .el-input__icon {
  line-height: 40px;
}

  .input-with-select ::v-deep .el-input-group__prepend {
    background-color: #fff;
  }

  ::v-deep .el-button+.el-button {
    margin: 0;
  }

  ::v-deep .el-loading-spinner {
    margin-top: -7px;
    margin-left: -30px;
  }
  ::v-deep  .el-loading-spinner .el-loading-text  {
    margin: -12px 0 0 60px;
  }

   ::v-deep  .el-input__icon {
    transform: translateY(-4px);
  }

  .threeBtns {
    background-color:#fff;
    padding: 24px 16px;
  }
  .btn1 {
    width: 80px;
    height: 32px;
  }
  .btn2 {
    width: 108px;
    height: 32px;
  }
  .btn3 {
    width: 108px;
    height: 32px;
  }
  .btn4 {
    width: 108px;
    height: 32px;
  }
  .el-table {
    padding: 0 16px;
  }

v-deep .el-form ,.el-form--label-letf ,.el-form--inline {
  width: 0;
}

@media screen and (max-width: 1800px) {
  .search ::v-deep .el-input__inner{
    width: 150px;
    height: 32px;
  }
}

</style>
