<template>
  <!-- 添加——新增主sku 弹窗 -->
  <el-dialog :title='td("关联WMS")' :visible="relationVisible"  width="80%"  @close="onClose()">
    <!-- 基础信息 -->
    <!-- <h4>{{td('基本信息')}}</h4> -->
	<div>
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-position="letf">
		  <div class="box">
		    <el-form-item :label="td('Seller SKU')" prop="customerGoodscode" class="item1">
		      <el-input v-model="queryParams.customerGoodscode" :placeholder="td('请输入Seller SKU')" clearable size="small" />
		    </el-form-item>
		    <el-form-item class="item4">
		      <el-button type="primary" size="small" @click="handleQuery" style="margin-right:16px">{{ td("搜索") }}</el-button>
		      <el-button type="primary" plain size="small" @click="resetQuery">{{ td("重置") }}</el-button>
		    </el-form-item>
		  </div>
		</el-form>
	</div>
	<el-table ref="table" :data="tabList" tooltip-effect="dark" row-key="motherSku" :row-style="{height: '80px'}" highlight-current-row @row-click="itemclick" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }" >
	  <!-- <el-table-column type="selection" align="center"  /> -->
	  <el-table-column label width="35">
	      <template slot-scope="scope">
	        <el-radio :label="scope.row.customerGoodscode" v-model="customerGoodscode"></el-radio>
	      </template>
	  </el-table-column>
	  <el-table-column prop="goodsBarcode" :label='td("仓库SKU")'  />
	  <el-table-column prop="goodsName" :label='td("货品名称")'  :show-overflow-tooltip="true" width="200">  <!-- :render-header="renderHeader" -->
	  <template slot-scope="scope">
		  {{td(scope.row.goodsName)}}
	  </template>
	  </el-table-column>
	  <el-table-column prop="customerGoodscode" :label='td("SELLER_SKU")'  />
	  <el-table-column prop="goodsModel" :label='td("货品型号")'  />
	  <el-table-column prop="customGoodsCode" :label='td("SHOP_SKU")'  />
	</el-table>

    <span slot="footer" class="dialog-footer">
      <el-button style="margin-right: 10px;" @click="onClose()">{{td("取消")}}</el-button>
      <el-button type="primary"  @click="onSave(tabList)">{{td("确定")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getrelation } from "@/api/warehouse/"
import { upsonSKU } from "@/api/goods/Erpmother";
import Cookies from "js-cookie"

export default {
  name: "RelationWms",
  props:['relationVisible','RelationList'],
  data() {
    return {
		tabList: [],
		queryParams: {
		  pageNum: 1,
		  pageSize: 10,
		  customerGoodscode: null,
		},
		customerGoodscode: '',
		radioList: {
			sellerSku: null,
			depotSku: null,
			skuStatus: "10825",
			wmsStatus: "10825"
		},
		templateSelection: []
    }
  },
  methods: {
		onClose(){
			this.$emit("update:relationVisible", false);
		},
		getrelationList(){
			getrelation().then((res) => {
			  this.tabList = res.rows;
			})
		},
		onSave(){
			this.RelationList.sellerSku = this.radioList.sellerSku;
			this.RelationList.depotSku = this.radioList.depotSku;
			this.RelationList.wmsStatus = this.radioList.wmsStatus;
			this.RelationList.skuStatus = this.radioList.skuStatus;
			upsonSKU(this.RelationList).then((res) => {
			  this.$emit("updateList");
			  this.$emit("update:relationVisible", false);
			  this.$message.success(this.td("操作成功"));
			});
		},
		//多选
		handleSelectionChange(selection) {
		  this.single = selection.length !== 1
		  this.multiple = !selection.length
		  this.selectData = selection
		},
		itemclick(row){
			this.customerGoodscode = row.customerGoodscode;
			this.radioList.sellerSku = row.customerGoodscode;
			this.radioList.depotSku = row.goodsBarcode;
		},
		handleQuery() {
		  this.queryParams.pageNum = 1;
		  this.getTableList();
		},
		getTableList(){
			getrelation(this.queryParams).then(res => {
				this.tabList = res.rows;
			})
		},
		resetQuery() {
		  this.queryParams = {
			pageNum: 1,
			pageSize: 10,
		    customerGoodscode: null,
		  }
		  this.getTableList();
		},
  }
}
</script>

<style scoped>
/* 弹窗 */
::v-deep .el-dialog {
  margin: 5vh auto !important;
}

::v-deep .el-dialog__body {
  padding: 0 20px 10px 20px;
  height: 70vh;
  overflow: auto;
}
.add-sku .el-radio {
  margin-right: 15px;
}
::v-deep .el-table__header-wrapper .el-table-column--selection .el-checkbox {
    display: none !important;
}
</style>
