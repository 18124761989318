<template>
	<!-- 添加或修改子SKU对话框 -->
	<el-dialog :title='td("创建子SKU")' :close-on-click-modal="false" :visible="SonskuVisible" @close="onClose()"
		width="1000px" append-to-body>
		<el-form :model="form" ref="form" :rules="rules" label-position="top" label-width="80px">
			<el-col :span="8">
				<el-form-item :label="td('中文名')" prop="goodsName">
					<el-input v-model="form.goodsName" :placeholder="td('请输入商品中文名')" style="width: 96%" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('英文名')">
					<el-input v-model="form.sonEnname" :placeholder="td('请输入商品英文名')" style="width: 96%" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('本地名')" prop="localSonName">
					<el-input v-model="form.localSonName" :placeholder="td('请输入商品本地名')" style="width: 96%" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('Shop SKU')" prop="shopSku">
					<el-input v-model="form.shopSku" :placeholder="td('请输入Shop SKU')" style="width: 96%" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('规格型号')">
					<el-input v-model="form.model" :placeholder="td('请输入规格型号')" style="width: 96%" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('商品成本价')">
					<el-input v-model="form.goodsPrice" :placeholder="td('请输入商品成本价')" style="width: 96%" 
						oninput="value=value.replace(/[^\d.]/g,'')"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="10">
				<el-form-item :label='td("商品图片")'>
					<ImageUpload v-model="form.goodsImg" :pictureType="pictureType" />
				</el-form-item>
			</el-col>
		</el-form>
		<span slot="footer">
			<el-button @click="onClose()" style="margin-right: 10px;">{{td('取消')}}</el-button>
			<el-button type="primary" @click="onSave(form)" style="margin-right: 12px;">{{td('保存')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		addsonSKU
	} from "@/api/goods/Erpmother";
	import ImageUpload from "@/utils/ImageUpload/index.vue";
	import Cookies from "js-cookie";
	import {
		getListtt
	} from "@/api/warehouse/index";

	export default {
		name: "add-sku",
		props: ["SonskuVisible", "tabList"],
		components: {
			ImageUpload
		},
		data() {
			return {
				// 表单参数
				form: {},
				checkedErpSkuSon: [],
				// 子sku
				wmsInOrderDetailList: [],
				// 存放照片
				fileList: [],
				warhoureleList: [],
				formdata: {},
				pictureType: "picture-card",
				pictureSKUType: "picture",
			};
		},
		created() {
			// this.addwareList();
		},
		computed: {
			rules() {
				return {
					goodsName: [{
						required: true,
						message: this.td("中文名称不能为空"),
						trigger: "blur"
					}],
					localSonName: [{
						required: true,
						message: this.td("本地名不能为空"),
						trigger: "blur"
					}],
					shopSku: [{
						required: true,
						message: this.td("Seller SKU不能为空"),
						trigger: "blur"
					}],
				}
			},
		},
		methods: {
			addwareList() {
				getListtt().then((res) => {
					this.warhoureleList = res.rows;
				});
			},
			onClose() {
				this.$emit("update:SonskuVisible", false);
				this.reset()
			},
			reset() {
				this.form = {
					goodsName: null,
					sonEnname: null,
					localSonName: null,
					shopSku: null,
					model: null,
					goodsPrice: null,
					orderNo: null,
					waybillNo: null,
					sellerAccount: null,
					orderState: null,
					source: null,
					skuCode: null,
					orderId: null,
				};
				this.resetForm("form");
			},
			onSave(data) {
				data.depotId = this.tabList.depotId;
				data.motherSku = this.tabList.motherSku;
				data.userId = this.tabList.userId;
				this.$refs.form.validate(valid => {
					if (valid) {
						addsonSKU(data).then(res => {
							this.$emit("updateList");
							this.$emit("update:SonskuVisible", false);
							this.$message.success(this.td("操作成功"));
							this.reset();
						})
					}
				})
			},
		},
	}
</script>

<style scoped>
	/* 弹窗 */
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 0 20px 10px 20px;
		height: 70vh;
		overflow: auto;
	}

	.add-sku .el-radio {
		margin-right: 15px;
	}
</style>
