<template>
  <div>
    <div class="search">
      <el-form :model="queryParams" ref="queryForm" :inline="true" label-position="letf">
        <div class="box">
          <el-form-item :label="td('Seller SKU')" prop="keywords" class="item1">
            <el-input v-model="queryParams.keywords" :placeholder="td('请输入Seller SKU')" clearable size="small" />
          </el-form-item>
          <el-form-item :label="td('SKU来源')" prop="source">
            <el-select v-model="queryParams.source" :placeholder="td('请选择SKU来源')"  clearable size="small" class="item2">
              <el-option v-for="dict in tf('ERP_ORDER_SOURCE')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId" size="mini" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item :label="td('状态')" prop="wmsStatus"> -->
            <!-- <el-select v-model="queryParams.wmsStatus" :placeholder="td('请选择同步状态')"  clearable size="small" class="item3">
              <el-option v-for="dict in tf('WMS_STATUS')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId" size="mini" />
            </el-select> -->
			<!-- <el-select v-model="queryParams.wmsStatus" :placeholder="td('请选择状态')"  clearable size="small" class="item3">
			  <el-option v-for="dict in tf('WMS_STATUS')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId" size="mini" />
			</el-select> -->
          <!-- </el-form-item> -->
		  <el-form-item :label="td('店铺')" prop="shopId">
		    <el-select v-model="queryParams.shopId" :placeholder="td('请选择店铺')"  clearable size="small" class="item3">
		      <el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId" size="mini" />
		    </el-select>
		  </el-form-item>
          <el-form-item class="item4">
            <el-button type="primary" size="small" @click="handleQuery" style="margin-right:16px">{{ td("搜索") }}</el-button>
            <el-button type="primary" plain size="small" @click="resetQuery">{{ td("重置") }}</el-button>
          </el-form-item>
        </div>

      </el-form>
    </div>

    <div class="threeBtns">
      <el-button class="btn1" icon="el-icon-upload2"  type="primary"  size="small" @click="handleImport" >{{ td("导入") }}</el-button>

      <el-button class="btn2" icon="el-icon-refresh" type="primary" plain size="mini" style="float:right;"
      v-loading="fullscreenLoading" element-loading-spinner="el-icon-loading" :element-loading-text='td("加载中")'  @click="handlSynchronization">{{ td("获取SKU") }}</el-button >
      <!-- <el-button class="btn4" icon="el-icon-refresh-right" type="primary" plain  size="small" @click="handplTbu" style="float:right;margin:0 16px 0 0;">{{ td("批量同步") }}</el-button> -->
      <el-button class="btn3" icon="el-icon-plus" type="primary"  size="small"  @click.native="addskuDig = true"  style="float: right;margin:0 16px 0 0;">{{ td("新增主SKU") }}</el-button>
    </div>

	<el-tabs v-model="activeName" @tab-click="handleClick">
	    <el-tab-pane :label="td('全部')" name="first">
			<first ref="first" :ShopList="ShopList" />
		</el-tab-pane>
		<el-tab-pane :label="td('已关联')" name="second">
			<second ref="second" />
		</el-tab-pane>
	    <el-tab-pane :label="td('未关联')" name="Notsecond">
			<Not ref="Notsecond" />
		</el-tab-pane>
	    <el-tab-pane :label="td('部分关联')" name="third">
			<part ref="third" />
		</el-tab-pane>
	</el-tabs>

    <!-- 表格 -->

    <!-- 分页 -->
   <!-- <pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="handleClick" /> -->


    <!--添加主SKU按钮----弹窗组件 -->
    <addSku :addskuVisible.sync="addskuDig" @updateList="updateList" :warhoureleList="ShopList" :treeform="treeform" />
    <!--添加子SKU按钮----弹窗组件 -->
    <addSonsku :SonskuVisible.sync="addSonDig" :tabList="addSonlList" @updateList="updateList"/>
    <!--修改主SKU按钮----弹窗组件 -->
    <updateSku :addskuVisible.sync="updateskuDig" :tabList="updateModelList" :warhoureleList="ShopList" @updateList="updateList" ref="update" />
    <!--修改子SKU按钮----弹窗组件 -->
    <updateSonsku :SonskuVisible.sync="updateSonDig" :tabList="updateSonlList"/>
	<!--关联wms按钮----弹窗组件 -->
	<RelationWms :relationVisible.sync="relationDig" :tabList="relationList" :RelationList="checkrelationList" @updateList="updateList" />

    <!-- end -->

    <!-- 导入按钮 -->
    <el-dialog :title="td('ERP_SKU导入')" :visible.sync="upload.open" width="400px" append-to-body >
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url + upload.shopId + '?updateSupport=' + upload.updateSupport"
        :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{td("将文件拖到此处，或")}}<em>{{td("点击上传")}}</em></div>
        <div class="el-upload__tip text-center" slot="tip">
          <div class="el-upload__tip" slot="tip">
            <el-checkbox v-model="upload.updateSupport" />
            {{td("是否更新已经存在的用户数据")}}
          </div>
          <span>{{td("仅允许导入xls、xlsx格式文件。")}}</span>
          <el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline" @click="importTemplate">{{td("下载模板")}}</el-link>
        </div>
      </el-upload>
	  <div style="margin-top: 10px;">
		<el-select v-model="upload.shopId" :placeholder="td('请选择店铺')"  clearable size="small" class="item3">
		  <el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId" size="mini" />
		</el-select>
	  </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="upload.open = false" style="margin-right:15px;">{{td("取 消")}}</el-button>
        <el-button type="primary" @click="submitFileForm(upload)" >{{td("确 定")}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import addSku from "./dialog/addsku";
import addSonsku from "./dialog/addSonsku";
import updateSku from "./dialog/updatesku";
import updateSonsku from "./dialog/updateSonsku";
import RelationWms from "./dialog/RelationWms";
import first from "./Tabs/first";
import second from "./Tabs/second";
import Not from "./Tabs/Not";
import part from "./Tabs/part";
import axios from 'axios'

import { getListtt,getson,getshop,getrelation } from "@/api/warehouse/"
import {TreeList,delErpmother,Synchronization,delsonSKU,treeErpmother} from "@/api/goods/Erpmother.js";
import { synchronizationSku,synchronizationSkuShopee,synchronizationPlTbu } from "@/api/order/orderList.js"
import { getToken } from "@/utils/auth";
import {  Debounce,Throttle } from "@/utils/bd.js"

export default {
  name: "fristSKU",
  components: { addSku, addSonsku, updateSku, updateSonsku, RelationWms,first,second,Not,part},
  data() {
    return {
      val: "",
	  activeName: "first",
      total: 0,
      fullscreenLoading: false,
      form:{},
      // 页面父数据
      tableList: [],
      // 页面子数据
      sonTableList:[],
      //子表数据
      allotDetailMap: new Map(),
      // 级联选择器
      treeform: [],
      //内嵌 table key
      detailTableKey: false,
      // 控制弹窗的开关
      // catalogueDig: false,
      editskuDig: false,
      deleteskuDig: false,
      addskuDig: false,
      updateskuDig: false,
      updateSonDig: false,
      addSonDig: false,
      intoskuDig: false,
      outskuDig: false,
	  relationDig: false,
	  relationList: [],
	  checkrelationList: [],

      warhoureleList: [],
      addSonlList: [],

      treeKey: null,
      updateModelList: {},
      updateSonlList: {},

      queryParams: {
        keywords: null,
        skuType: null,
        source: null,
        wmsStatus: null,
        pageNum: 1,
        pageSize: 10,
      },

      upload: {
        // 是否显示弹出层
        open: false,
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: this.baseURL + "/erp/Erpmother/importErpSku/",
      },
      //批量同步到云仓
      single: true,
      multiple: true,
      selectData:[],
      handplTbuData:[],
	  ShopList: []
    }
  },
  created() {
 //    this.getTableList()
    this.getShop()
 //    this.addwareList()
	// this.getrelationList();
  },
  computed:{
  	showShopType: function() {
  	  return function(number) {
  	    for (let i = 0; i < this.ShopList.length; i++) {
  	      if (number == this.ShopList[i].shopId) {
  	        return this.ShopList[i].name
  	      }
  	    }
  	  }
  	},
  },
  methods: {
    // 获取页面数据
    getTableList() {
	 // this.activeName = "first";
      TreeList(this.queryParams).then( res => {
        this.tableList = res.rows
        this.total = res.total
      })
    },
	handleClick(){
		// console.log(this.activeName);
		// let obj = {
		// 	wmsStatus: null,
		// 	pageNum: this.queryParams.pageNum,
		// 	pageSize: this.queryParams.pageSize,
		// }
		if(this.activeName == "first"){
			this.$refs['first'].loadData();
		}else if(this.activeName == "second"){
			this.$refs['second'].loadData();
		}else if(this.activeName == "Notsecond"){
			this.$refs['Notsecond'].loadData();
		}else if(this.activeName == "third"){
			this.$refs['third'].loadData();
		}
	},
    addwareList() {
      getListtt().then((res) => {
        this.warhoureleList = res.data;
      })
    },
	getShop() {
	  getshop().then((res) => {
	    this.ShopList = res.rows;
	  })
	},
	getrelationList(){
		getrelation().then((res) => {
		  this.relationList = res.rows;
		})
	},
    xiugai(data) {
      this.updateModelList = data;
      this.updateskuDig = true;
	  this.$refs.update.getTableList(this.updateModelList);
    },
    updateSon(data) {
      this.updateSonlList = data;
      this.updateSonDig = true;
    },
	checkrelationWms(data){
	  this.checkrelationList = data;
	  this.relationDig = true;
	},
    AddsSKU(data) {
      this.addSonlList = data;
      this.addSonDig = true;
    },
    //搜索选择品类
    changeValue(data) {
      // console.log(data);
      this.queryParams.skuType = data
    },
    reset() {
      this.form = {
        keywords: null,
        skuType: null,
        source: null,
        wmsStatus: null,
      }
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
	  if(this.activeName == "first"){
		this.$refs.first.receiveList(this.queryParams)
	  }else if(this.activeName == "second"){
		this.$refs.second.receiveList(this.queryParams);
	  }else if(this.activeName == "Notsecond"){
		this.$refs.Notsecond.receiveList(this.queryParams);
	  }else if(this.activeName == "third"){
		this.$refs.third.receiveList(this.queryParams);
	  }
      // this.getTableList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.resetForm("queryForm");
      this.handleQuery();
    },
    //同步SKU
    handlSynchronization:
      Throttle( function() {
        this.fullscreenLoading = true,
        axios.all([ synchronizationSku(),synchronizationSkuShopee() ]).then(
          axios.spread( () => {
            this.$message.success(this.td("同步成功"))
            this.fullscreenLoading = false;
            this.getTableList()
          })
        ).catch( () => {
            this.$message.success(this.td("同步成功"))
            this.fullscreenLoading = false;
            this.getTableList()
          })
      },3000),
    //同步到云仓
    handleTbu(val1) {
      // Synchronization(val1).then( res => {
      //   this.$message.success(this.td("同步成功"))
      //   this.getTableList();
      //   this.depotId = null
      // });
      let datas = [val1]
      synchronizationPlTbu(datas).then( res =>{
        if(res.code !== 200) {
          return this.$message.error( this.td(res.msg))
        }
        this.$message.success(this.td(res.msg))
        this.getTableList();
      })
    },
    //多选
    handleSelectionChange(selection) {
      this.single = selection.length !== 1
      this.multiple = !selection.length
      this.selectData = selection
    },
    //批量同步到云仓
    handplTbu() {
      if(this.selectData.length == 0 ) {
        return this.$message.warning( this.td('请选择批量数据'))
      }

      for (let index = 0; index < this.selectData.length; index++) {
        // if(this.selectData[index].wmsStatus = "10825") {
        //   return this.$message.warning(this.td('选中的数据中，部分已同步，请勿重复操作！'))
        // }
        this.handplTbuData.push(this.selectData[index].motherSku)
      }

      synchronizationPlTbu(this.handplTbuData).then( res =>{
        if(res.code !== 200) {
          return this.$message.error( this.td(res.msg))
        }
        this.$message.success(this.td(res.msg))
        this.getTableList();
      })
      this.handplTbuData =[]

    },
    //根据(母SKU)删除SKU
    async handleDelete(id) {
      const confirmResult = await this.$confirm(
        this.td("此操作将删除(主键母SKU)，是否继续?"),
        this.td("提示"),
        {
          confirmButtonText: this.td("确定") ,
          cancelButtonText: this.td("取消"),
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") return this.$message.info( this.td("已取消删除"));
      delErpmother(id).then((res) => {
        if (res.code !== 200) {
          return this.$message.error( this.td("删除“主键母SKU”失败") );
        }
        this.$message.success( this.td("删除“主键母SKU”成功"));
        this.getTableList()
        this.addwareList()
      });
    },
    //根据(子SKU)删除SKU
    async delSon(id) {
      const confirmResult = await this.$confirm(
        this.td("此操作将删除(子SKU)，是否继续?"),
        this.td("提示"),
        {
          confirmButtonText: this.td("确定"),
          cancelButtonText: this.td("取消"),
          type: "warning",
        }
      ).catch((err) => err)
      if (confirmResult !== "confirm") return this.$message.info( this.td("已取消删除"));
      delsonSKU(id).then((res) => {
        if (res.code !== 200) {
          return this.$message.error( this.td("删除“子SKU”失败"));
        }
        this.$message.success( this.td("删除“子SKU”成功"));
        this.getTableList();
      })
    },

    getAllotDetail(row) {
      //根据 motherSku 获取子表
      getson(row).then( res => {
        // 使用 map 结构的方式保存子表
        this.allotDetailMap.set(row,res.rows);
        this.detailTableKey = !this.detailTableKey;
        this.reset();
      })
    },
    //点击母商品名，显示子SKU
   //  toogleExpand(val) {
   //    this.getAllotDetail(val.motherSku);
	  // if(this.activeName == "first"){
		 //  let $table = this.$refs.ALLtable;
		 //  $table.toggleRowExpansion(val);
	  // }else if(this.activeName == "second"){
		 //  let $table = this.$refs.Asstable;
		 //  $table.toggleRowExpansion(val);
	  // }else if(this.activeName == "Notsecond"){
		 //  let $table = this.$refs.Nottable;
		 //  $table.toggleRowExpansion(val);
	  // }else if(this.activeName == "third"){
		 //  let $table = this.$refs.Partialtable;
		 //  $table.toggleRowExpansion(val);
	  // }
   //  },
    updateList() {
      this.getTableList();
    },
    //选择仓库的值
    chanSelect(val) {
      this.depotId = val
    },


    // handleFileUploadProgress(event, file, fileList) {
    //   this.upload.isUploading = true;
    // },
    // handleFileSuccess(response, file, fileList) {
    //   this.upload.open = false;
    //   this.upload.isUploading = false;
    //   this.$refs.upload.clearFiles();
    //   this.$alert(response.msg, this.td("导入结果"), { dangerouslyUseHTMLString: true });
    //   this.getOrder()
    // },
    handleImport() {
      this.upload.open = true;
    },
    /** 下载模板操作 */
    importTemplate() {
      // this.download(
      //   "https://interface.6pl.com.cn/erp/Erpmother/importTemplate",
      //   {
      //     ...this.queryParams,
      //   },
      //   `sku_${new Date().getTime()}.xlsx`
      // );
	  window.open('/excel_model/ERP_SKU.xlsx',"_blank");
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(this.td('导入成功'), this.td("导入结果"), { dangerouslyUseHTMLString: true });
      this.$refs.first.receiveList(this.queryParams)
	  this.upload.shopId = null;
    },
    // 提交上传文件
    submitFileForm(upload) {
	  if(this.upload.shopId == null){
		this.$message.info( this.td("请选择店铺"))
	  }else {
		// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/Erpmother/importData?shopId="+this.upload.shopId,
		// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/Erpmother/importData/"+this.upload.shopId,
		console.log(this.upload);
		this.$refs.upload.submit();
	  }
    },
    // renderHeader(h, {column}) {
    //   let header = column.label.split(' ');
    //   return [h('p', [
    //     h('p', {}, header[0]),
    //     h('span', {}, header[1])
    //   ])];
    // },
  },
}
</script>

<style scoped>
.search {
  position: relative;
  background-color: #fff;
  height: 80px;
  margin-bottom: 16px;
}
.box {
  position: absolute;
  top: 20px;

}
.search .el-form {
  margin: 0 16px;
  color: #333333;
  margin-right: 0;
}

  .search .el-form-item {
   margin-bottom: 0;
 }
  .search ::v-deep .el-form-item__label {
    margin-right: 24px;
    padding: 0;
  }

  .search ::v-deep .el-input__inner{
    width: 220px;
    height: 32px;
  }
  .search ::v-deep .el-form--inline .el-form-item {
   margin-right: 0;

 }
 .el-form-item:not(:first-child) {
   margin-left: 32px;
 }
 .el-form-item:last-child {
    margin-left: 16px;
 }

 .btns {
   position: absolute;
   top: 24px;
   right: 16px;
 }
 .search .el-button{
  width: 74px;
  height: 32px;
 }

.search .item1 ::v-deep .el-input__suffix {
  line-height: 50px;
}
.search .item3 ::v-deep .el-input__icon {
  line-height: 40px;
}
.search .item2 ::v-deep .el-input__icon {
  line-height: 40px;
}

  .input-with-select ::v-deep .el-input-group__prepend {
    background-color: #fff;
  }

  ::v-deep .el-button+.el-button {
    margin: 0;
  }

  ::v-deep .el-loading-spinner {
    margin-top: -7px;
    margin-left: -30px;
  }
  ::v-deep  .el-loading-spinner .el-loading-text  {
    margin: -12px 0 0 60px;
  }

   ::v-deep  .el-input__icon {
    transform: translateY(-4px);
  }

  .threeBtns {
    background-color:#fff;
    padding: 24px 16px;
  }
  .btn1 {
    width: 80px;
    height: 32px;
  }
  .btn2 {
    width: 108px;
    height: 32px;
  }
  .btn3 {
    width: 108px;
    height: 32px;
  }
  .btn4 {
    width: 108px;
    height: 32px;
  }
  .el-table {
    padding: 0 16px;
  }

v-deep .el-form ,.el-form--label-letf ,.el-form--inline {
  width: 0;
}

@media screen and (max-width: 1800px) {
  .search ::v-deep .el-input__inner{
    width: 150px;
    height: 32px;
  }
}



</style>
