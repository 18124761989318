import request from '@/utils/request'
import { getToken } from "@/utils/auth"

// 同步操作(传父表主键)
export function Synchronization(val1) {
  return request({
    url: '/erp/Erpmother/Synchronization?MotherSku='+ val1,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
  })
}

// 同步操作(传子表主键)
export function SynchronizationSon(data) {
  return request({
    url: '/erp/SkuSon/Synchronization?skuSon='+data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
  })
}

// 获取主子表的树形数据
export function TreeList(data) {
  return request({
    url: '/erp/Erpmother/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
	params: data
  })
}

// 查询ERPsku管理详细
export function getErpmother(motherSku) {
  return request({
    url: '/erp/Erpmother/erp/' + motherSku,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

// 新增ERPsku管理
export function addErpmother(data) {
  return request({
    url: '/erp/Erpmother/erp',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: data
  })
}

// 修改ERPsku管理
export function updateErpmother(data) {
  return request({
    url: '/erp/Erpmother/erp',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'put',
    data: data
  })
}

// 删除ERPsku管理
export function delErpmother(motherSku) {
  return request({
    url: '/erp/Erpmother/erp/' + motherSku,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}

// 树形品类下拉框
export function treeErpmother() {
  return request({
    url: '/erp/erpType/erpTree',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

export function listErpmother(query) {
  return request({
    url: '/erp/Erpmother/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// 新增子SKU信息
export function addsonSKU(data) {
  return request({
    url: '/erp/Erpmother/insErpSkuSon',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
	data: data
  })
}

// 修改子SKU信息
export function upsonSKU(data) {
  return request({
    url: '/erp/Erpmother/UpdateSkuSon',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
	data: data
  })
}

// 删除子SKU信息
export function delsonSKU(sonSku) {
  return request({
    url: '/erp/Erpmother/deleteSkuSon/' + sonSku,
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post'
  })
}


