<template>
	<!-- 添加或修改子SKU对话框 -->
	<el-dialog :title="td('修改子SKU')" :close-on-click-modal="false" :visible="SonskuVisible" @close="onClose()"
		width="1000px" append-to-body>
		<el-form ref="form" :model="form" label-position="top" label-width="80px">
			<!-- <el-col :span="12"> </el-col> -->
			<el-col :span="8">
				<el-form-item :label="td('中文名')" prop="goodsName">
					<el-input v-model="tabList.goodsName" :placeholder="td('请输入中文名')" style="width: 300px" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('英文名')" prop="sonEnname">
					<el-input v-model="tabList.sonEnname" :placeholder="td('请输入英文名')" style="width: 300px" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('本地名')" prop="localSonName">
					<el-input v-model="tabList.localSonName" :placeholder="td('请输入本地名')" style="width: 320px" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('Shop SKU')" prop="shopSku">
					<el-input v-model="tabList.shopSku" :placeholder="td('请输入Shop SKU')" style="width: 300px" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('规格型号')" prop="model">
					<el-input v-model="tabList.model" :placeholder="td('请输入规格型号')" style="width: 300px" />
				</el-form-item>
			</el-col>
			<el-col :span="8">
				<el-form-item :label="td('商品成本价')" prop="goodsPrice">
					<el-input v-model="tabList.goodsPrice" :placeholder="td('请输入商品成本价')" style="width: 320px" />
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label="td('商品长度')">
					<el-input v-model="tabList.goodsLength" :pictureType="pictureType" :placeholder="td('请输入商品长度')"
						style="width: 225px" />
					<template slot="append">cm</template>
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label="td('商品宽度')">
					<el-input v-model="tabList.goodsWidth" :pictureType="pictureType" :placeholder="td('请输入商品宽度')"
						style="width: 225px" />
					<template slot="append">cm</template>
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label="td('商品高度')">
					<el-input v-model="tabList.goodsHeight" :pictureType="pictureType" :placeholder="td('请输入商品高度')"
						style="width: 225px" />
					<template slot="append">cm</template>
				</el-form-item>
			</el-col>

			<el-col :span="6">
				<el-form-item :label="td('商品重量')">
					<el-input v-model="tabList.goodsWeight" :pictureType="pictureType" :placeholder="td('请输入商品重量')"
						style="width: 240px" />
					<template slot="append">cm</template>
				</el-form-item>
			</el-col>

			<el-col :span="8">
				<el-form-item :label="td('商品图片')">
					<ImageUpload v-model="tabList.goodsImg" :pictureType="pictureType" />
				</el-form-item>
			</el-col>


		</el-form>
		<div slot="footer" class="dialog-footer">
			<!-- <el-button type="primary" @click="submitForm">{{td("确定")}}</el-button>         <el-button @click="cancel">{{td("取消")}}</el-button>  -->
		</div>
		<!-- </el-dialog> -->

		<span slot="footer" class="dialog-footer">
			<el-button style="margin-right: 10px;" @click="onClose(tabList.motherSku)">{{ td("取消") }}</el-button>
			<el-button type="primary" style="margin-right: 2px;" @click="onSave(tabList)">{{
        td("保存")
      }}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		upsonSKU
	} from "@/api/goods/Erpmother";
	import ImageUpload from "@/utils/ImageUpload/index.vue";
	import Cookies from "js-cookie";

	export default {
		name: "add-sku",
		props: ["SonskuVisible", "tabList"],
		components: {
			ImageUpload
		},
		data() {
			return {
				// 下拉框测试
				options: [],
				value: "",
				// 表单参数
				form: {},
				checkedErpSkuSon: [],
				// 子sku
				wmsInOrderDetailList: [],
				// 存放照片
				fileList: [],
				addform: {
					localMotherName: null,
					motherEnname: null,
					motherName: null,
					motherSku: null,
					imageUrl: null,
					remark: null,
					goodsLength: null,
					Wide: null,
					goodsWidth: null,
					goodsHeight: null,
					warehouse: null,
					depotId: null,
					username: null,
					erpSkuSonList: [],
				},
				adrules: {
					goodsPrice: [{
						required: true,
						message: this.td("母商品名不能为空"),
						trigger: "blur"
					}, ],
				},
				formdata: {},
				pictureType: "picture-card",
				pictureSKUType: "picture",
			};
		},
		methods: {
			lopk(data) {
				// console.log(data);
			},
			onClose(e) {
				console.log(e);
				let der = {
					motherSku:e
				}
				this.$emit("Sonsku",e)
				this.$emit("update:SonskuVisible", false);
			},
			onSave(data) {
				console.log(data);
				data.username = Cookies.get("username");
				upsonSKU(data).then((res) => {
					this.$emit("updateList");
					this.$emit("update:SonskuVisible", false);
					this.$message.success(this.td("修改成功"));
				});
			},
			handleAddWmsInOrderDetail() {
				let obj = {};
				obj.goodsWidth = this.addform.Wide;
				obj.goodsHeight = this.addform.goodsHeight;
				obj.goodsLength = this.addform.goodsLength;
				obj.goodsWeight = this.addform.goodsWidth;
				obj.goodsPrice = "";
				obj.goodsName = "";
				obj.goodsImg = "";
				obj.remark = "";
				obj.stockNum = "";
				obj.wholeOut = "";
				obj.auditOut = "";
				obj.depotId = "";
				obj.userId = "";
				obj.shopSku = "";
				obj.depotSku = "";
				obj.model = "";
				obj.skuStatus = "";
				obj.wmsStatus = "";
				obj.sonEnname = "";
				obj.localSonName = "";
				this.tabList.erpSkuSonList.push(obj);
				console.log(this.tabList);
			},
			/** 入库明细序号 */
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1;
			},
			handleWmsInOrderDetailSelectionChange(selection) {
				this.checkedErpSkuSon = selection.map((item) => item.index);
			},
			/** 入库明细删除按钮操作 */
			hhandleDeleteErpSkuSon() {
				if (this.checkedErpSkuSon.length == 0) {
					this.msgError(
						this.td("请先选择要删除的子SKU数据")
					); //111
				} else {
					const erpSkuSonList = this.tabList.erpSkuSonList;
					const checkedErpSkuSon = this.checkedErpSkuSon;
					this.tabList.erpSkuSonList = erpSkuSonList.filter(function(item) {
						return checkedErpSkuSon.indexOf(item.index) == -1;
					});
				}
			},
		},
	};
</script>

<style scoped>
	/* 弹窗 */
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 0 20px 10px 20px;
		height: 70vh;
		overflow: auto;
	}

	.add-sku .el-radio {
		margin-right: 15px;
	}
</style>
